<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <div class="pad-container flx-row ali-c">
        <el-input
          placeholder="请输入商品条形码"
          class="search-input"
          v-model="searchObj.goodsCode"
          clearable
        ></el-input>
        <el-input
          placeholder="请输入商品名称"
          class="search-input ml-15"
          v-model="searchObj.goodsName"
          clearable
        ></el-input>
        <el-cascader
          class="ml-15"
          v-model="classifyName"
          :options="classifyList"
          :props="props"
          :show-all-levels="false"
          placeholder="请选择商品分类"
        ></el-cascader>
        <el-select
          placeholder="请选择商品状态"
          class="search-input ml-15"
          v-model="searchObj.status"
        >
          <el-option
            v-for="(item, index) in goodsStatusOptions"
            :key="index"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          icon="el-icon-search"
          class="ml-15"
          @click="search"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
          >重置</el-button
        >
      </div>
    </el-row>

    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <el-button type="primary" class="mb-10" @click="addGoods"
            >添加商品</el-button
          >
          <el-tabs
            v-model="activePart"
            @tab-click="changeTab"
            v-if="!storeFlag"
          >
            <el-tab-pane
              :label="item.storeName"
              :name="item.id + ''"
              v-for="(item, index) in shopList"
              :key="index"
            ></el-tab-pane>
          </el-tabs>
          <div>
            <!-- 表格 -->
            <el-table class="mt-24 member-table" :data="tableData" >
              <el-table-column label="序号">
                <template slot-scope="scope">{{
                  scope.$index + 1 + (pageNum - 1) * pageSize
                }}</template>
              </el-table-column>
              <el-table-column
                label="商品条形码"
                prop="goodsCode"
              ></el-table-column>
              <el-table-column
                label="商品名称"
                prop="goodsName"
              ></el-table-column>
              <el-table-column
                label="一级分类"
                prop="classifyName"
              ></el-table-column>
              <el-table-column
                label="二级分类"
                prop="secondClassifyName"
              ></el-table-column>
              <el-table-column
                label="所属门店"
                prop="storeName"
              ></el-table-column>
              <el-table-column label="价格类型" prop="priceType">
                <template slot-scope="scope">
                  <span>{{ scope.row.priceType == 1 ? "原价" : "实价" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="金额（元）" prop="priceType">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.priceType == 1
                      ? scope.row.originalPrice
                      : scope.row.actualPrice
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="重量（g）"
                prop="goodsWeight"
              ></el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <div class="flx-row ali-c">
                    <el-link
                      type="primary"
                      :underline="false"
                      @click="handleDel(scope.row.goodsId)"
                      style="color:#F56c6c"
                    >
                      <i class="el-icon-delete"></i>
                      删除
                    </el-link>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label = "排序序号">
                <template slot="header" slot-scope="scope">
                  <el-button class="batchDelete" @click="batchSort">确认排序</el-button>
                </template>
                <template slot-scope="scope">
                  <el-input v-model="scope.row.sort" placeholder="请输入排序序号"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="checked" align="center" width="150">
                <template slot="header" slot-scope="scope">
                  <el-checkbox v-model="allChecked" @change="AllcheckedChange()"  :indeterminate="isIndeterminate"></el-checkbox>
                  <el-button class="batchDelete" @click="batchDelete">批量删除</el-button>
                </template>
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.checked" @change="checkedGoods(scope.row)"></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <GoodsDialog
      ref="GoodsDialog"
      :tableHeader="tableHeader"
      :hasTab="true"
      :tabList="tabList"
      :storeId="activePart"
      :searchArr="searchArr"
      :selected="true"
      @getSelectedList="getSelectedList"
    ></GoodsDialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import GoodsDialog from "@/components/GoodsDialog";
import {
  getRecommendList,
  addRecommendedGoods,
  delRecommendedGoods,
  listOfPage,
  queryGoodsType,
  updateSort
} from "@/api/market/recommend";

export default {
  name: "goods_list",
  components: {
    Pagination,
    GoodsDialog,
  },
  data() {
    return {
      props: {
        label: "classifyName",
        value: "id",
        children: "childList",
      },
      act_form: {},
      searchArr: [
        {
          name: "goodsCodes",
          value: "",
          placeholder: "请输入商品条形码",
        },
        {
          name: "goodsName",
          value: "",
          placeholder: "请输入商品名称",
        },
      ],
      tabList: [
        {
          label: "商超商品",
          name: "1",
          url: "/shd-operation/lrGoodsInfo/queryGoodsInfo",
          method: "post",
          key: "goodsId",
          type: "1",
        },
        {
          label: "生鲜商品",
          name: "2",
          url: "/shd-operation/lrGoodsInfo/queryGoodsInfo",
          method: "post",
          key: "goodsId",
          type: "2",
        },
      ],
      tableHeader: [
        {
          value: "商品条形码",
          key: "goodsCode",
        },
        {
          value: "商品名称",
          key: "goodsName",
        },
        {
          value: "一级类目",
          key: "classifyName",
        },
        {
          value: "二级类目",
          key: "secondClassifyName",
        },
        {
          value: "实价",
          key: "actualPrice",
        },
        {
          value: "库存",
          key: "stock",
        },
      ],
      activePart: "1",
      // 顶部搜索栏数据
      searchObj: {
        goodsCode: "",
        goodsName: "",
        status: "",
      },
      classifyName: [],
      classifyList: [],
      // 商品状态下拉选项
      goodsStatusOptions: [
        { id: 0, name: "下架" },
        { id: 1, name: "上架" },
      ],
      // 拼团表格数据内容
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      shopList: [],
      upFlag: true,
      storeFlag: false,
      // -----------------分割线---------------------------------
      allChecked:false,//全选状态
      isIndeterminate:false
    };
  },
  mounted() {
    if (this.$store.getters.storeId) {
      this.activePart = String(this.$store.getters.storeId);
      this.storeFlag = true;
    }
    this.getClassifyList();
    this.getStoreList();
  },
  methods: {
    getRowKey(row){
      return row.index
    },
    // 获取分类列表
    getClassifyList() {
      queryGoodsType().then((res) => {
        this.classifyList = res.data.body;
      });
    },
    handleDel(id) {
      this.isConfirm(this, () => {
        delRecommendedGoods([id]).then(() => {
          this.getGoodsList();
        });
      });
    },
    addGoods() {
      let data = this.$refs.GoodsDialog;
      data.showGoods = true;
      data.getGoods(true);
    },
    //   获取已勾选商品
    getSelectedList(data) {
      this.$set(this.act_form, "list", data);
      let goodsIds = [];
      this.act_form.list.forEach((item) => {
        goodsIds.push(item.goodsId);
      });
      addRecommendedGoods(goodsIds).then(() => {
        this.getGoodsList();
      });
    },
    //获取门店列表
    getStoreList() {
      listOfPage().then((res) => {
        this.shopList = res.data.body.list;
        this.getGoodsList();
      });
    },
    goSee() {
      this.$router.push("/login");
    },
    // 编辑
    handleEdit(id) {
      this.$router.push({ name: "Goods_new", query: { id } });
    },
    // 上下架验证
    toggle(row) {
      let flag = row.status;
      row.status = row.status ? 0 : 1;
      if (row.status) {
        this.$confirm("确定要下架吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }).then(() => {
          if (flag) {
            row.status = 1;
            // this.toggleReq();
          } else {
            row.status = 0;
          }
        });
      } else {
        // 未完成商品必填信息无法上架
        this.$message.warning("请完善商品信息");
      }
    },
    // 上下架请求
    toggleReq() {
      request({
        url,
        method: "put",
      }).then((res) => {
        this.getGoodsList();
      });
    },
    // 切换tab
    changeTab() {
      this.getGoodsList();
    },
    //数据初始化
    getGoodsList() {
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        storeId: Number(this.activePart),
      };
      params = Object.assign(params, this.searchObj);
      let length = this.classifyName.length;
      if (length) {
        length >= 1 ? (params.parentClassifyId = this.classifyName[0]) : "";
        length >= 2 ? (params.classifyId = this.classifyName[1]) : "";
      }
      getRecommendList(params).then((res) => {
        let data = res.data.body;
        this.total = data.total;
        // this.tableData = data.list;
        console.log(data.list)
        let result=data.list.map((item)=>{
          this.$set(item,'checked',false)
          return item
        });
        console.log(data)
        this.tableData=result
        
      });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getGoodsList();
    },
    // 重置搜索关键词
    reset() {
      for (let key in this.searchObj) {
        this.searchObj[key] = "";
      }
      this.classifyName = [];
      this.search();
    },
    // 页面列表 pageNum pageSize获取
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.getGoodsList();
    },
    // 单选多选全选-----start
    AllcheckedChange(){
      let checkedNums = 0
      this.tableData.map((item)=>{
        if(item.checked === false){
          ++checkedNums
        };
      })
      if(checkedNums===0 || checkedNums===this.tableData.length){
        this.tableData.map((item)=>{
          this.isIndeterminate = false
          item.checked=!item.checked
        })
      }else{
        this.isIndeterminate=false
        this.allChecked = true
        this.tableData.map((item)=>{
          item.checked= true
        })
      }
      console.log(checkedNums)
      this.$forceUpdate()
    },
    // 批量选择
    checkedGoods(){
      let checkedNums = 0
      this.tableData.map((item)=>{
        if(item.checked === true){
          ++checkedNums
        };
      })
      console.log(checkedNums)
      if(checkedNums === 0){
        this.allChecked = false
        this.isIndeterminate= false
      }else if(checkedNums === this.tableData.length){
        this.isIndeterminate = false
        this.allChecked = true 
      }else{
        this.isIndeterminate= true
      }
    },
    // 删除选中商品
    batchDelete(){
      this.isConfirm(this, () => {
        let idList=[]
        this.tableData.map((item)=>{
          if(item.checked===true){
            idList.push(item.goodsId.toString())
          };
        })
        console.log(idList)
        delRecommendedGoods(idList).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
            duration:3000
          })
          this.checked = false
          this.isIndeterminate = false
          this.getGoodsList();
        });
      });
    },
    // 修改当前页面数据排序序号
    // --------------------

    batchSort(){
      let temp = {
        goodsId:'',
        sort:''
      }
      let data = []
      for(let i=0;i<this.tableData.length;i++){
       data.push({goodsId:'',sort:''})
       data[i].goodsId = this.tableData[i].goodsId
       data[i].sort = this.tableData[i].sort
      }
      updateSort(data).then(res=>{
        console.log(res)
      })
    }
  },
  filters: {
    statusFilter(val) {
      switch (val) {
        case 0:
          return "等待中";
        case 1:
          return "进行中";
        case 2:
          return "已结束";
        default:
          return "状态未知";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  .batchDelete{
    color: #f56c6c;
    padding: 3px 5px;
    margin-left:12px;
  }
}
.el-avatar /deep/ img {
  width: 100%;
}
.el-tabs /deep/ .el-tabs__header {
  margin: 0;
}
</style>
