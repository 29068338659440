<template>
  <!-- 查看商品列表弹窗组件 -->
  <el-dialog :visible="showGoods" @close="close" width="800px">
    <div class="pad-container">
      <div class="flx-row ali-c js-btw" v-if="isSearch">
        <div>
          <el-input
            :placeholder="item.placeholder"
            class="form-input-200 mr-10"
            v-model.trim="item.value"
            v-for="(item,index) in searchArr"
            :key="index"
            clearable
          ></el-input>
        </div>
        <div>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-search"
            class="ml-15"
            @click="search"
          >查询</el-button>
          <el-button size="small" icon="el-icon-refresh" class="ml-15" @click="refresh">刷新</el-button>
        </div>
      </div>
      <el-tabs v-model="activeName" @tab-click="handleTab" v-if="hasTab" class="mt-10">
        <el-tab-pane
          :label="item.label"
          :name="item.name"
          v-for="(item,index) in tabList"
          :key="index"
        >
          <el-table
            class="mt-24 member-table"
            :data="tableData"
            :ref="'multipleTable'+index?index:''"
            @select-all="handleSelectAll"
            @select="handleSelect"
            :row-key="(row)=>{ return row[key] }"
          >
            <el-table-column type="selection" :reserve-selection="true" v-if="selected"></el-table-column>
            <el-table-column :label="item.value" v-for="(item) in tableHeader" :key="item.key">
              <template slot-scope="scope">{{scope.row[item.key]}}</template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-table
        v-else
        class="mt-24 member-table"
        :data="tableData"
        ref="multipleTable"
        @select-all="handleSelectAll"
        @select="handleSelect"
        :row-key="(row)=>{ return row[key] }"
      >
        <el-table-column type="selection" :reserve-selection="true" v-if="selected"></el-table-column>
        <el-table-column :label="item.value" v-for="(item) in tableHeader" :key="item.key">
          <template slot-scope="scope">{{scope.row[item.key]}}</template>
        </el-table-column>
      </el-table>
      <Pagination
        :total="total"
        :pageNum="pageNum"
        :pageSize="pageSize"
        :hideSizes="true"
        :type="'dialog'"
        @syncPageData="syncPageData"
      />
    </div>
    <div class="flx-row ali-c js-fe mt-50" v-if="selected">
      <el-button plain size="medium" @click="cancelSelect">取消</el-button>
      <el-button class="ml-15" size="medium" type="primary" @click="chooseGoods">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import Pagination from "@/components/Pagination";
export default {
  props: {
    storeId: String,
    tabList: Array,
    isSearch: {
      type: Boolean,
      default: true
    },
    hasTab: {
      type: Boolean,
      default: false
    },
    tableHeader: Array,
    url: String,
    method: {
      type: String,
      default: "get"
    },
    selected: Boolean,
    searchArr: {
      type: Array,
      default: () => [
        {
          name: "barCode",
          value: "",
          placeholder: "请输入商品条形码"
        },
        {
          name: "goodsName",
          value: "",
          placeholder: "请输入商品名称"
        }
      ]
    },
    keyId: {
      type: [String, Number],
      default: "goodsId"
    }
  },
  components: {
    Pagination
  },
  mounted() {
    if (this.hasTab) {
      this.url1 = this.tabList[0].url;
      this.method1 = this.tabList[0].method;
    }
  },
  data() {
    return {
      activeName: "1",
      showGoods: false,
      pageNum: 1,
      pageSize: 5,
      tableData: [],
      total: 0,
      firstFlag: true,
      selected_temp: [],
      act_form: {
        list: []
      },
      tableName: null,
      url1: this.url,
      method1: this.method,
      key: this.keyId,
      index: 0,
      type: '1',
      id: ""
    };
  },
  methods: {
    // 切换tab
    handleTab(val) {
      this.pageNum = 1;
      this.tableName = this.$refs[val.index][0];
      let data = this.tabList[val.index];
      this.type = data.type;
      this.key = data.key;
      this.url1 = data.url;
      this.method1 = data.method;

      this.getGoods(true);
    },
    // 取消勾选
    cancelSelect() {
      this.showGoods = false;
      this.selected_temp = JSON.parse(JSON.stringify(this.act_form.list));
    },
    // 关闭弹窗
    close() {
      this.showGoods = false;
    },
    // 换页
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
      this.getGoods(this.selected ? true : "");
    },
    //获取商品列表
    getGoods(isSelect) {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      };
      let searchObj = {};
      searchObj.type = this.type;
      if (this.isSearch) {
        this.searchArr.map(item => {
          searchObj[item.name] = item.value;
        });
        searchObj.goodsCodes=searchObj.goodsCodes.replace(/\s/g,'').replace(/[^0-9]/g,',')
        console.log(searchObj.goodsCodes.length)
        data = Object.assign(data, searchObj);
      }
      this.id ? (data.id = this.id) : "";
      this.storeId ? data.storeId = this.storeId : ''
      
      let reqParams = {
        url: this.url1,
        method: this.method1
      };
      reqParams =
        this.method1 === "post"
          ? Object.assign(reqParams, { data })
          : Object.assign(reqParams, { params: data });
      //丽日接口字段
      if(this.storeId) {
        reqParams.data.goodsType = reqParams.data.type
        delete reqParams.data.type
      }
      $http(reqParams).then(res => {
        this.total = res.data.body.total;
        this.tableData = res.data.body.list;
        if (!this.selected) return;
        // 第一次调用
        if (this.firstFlag) {
          // console.log("第一次调用");
          this.firstFlag = false;
          this.hasTab ? (this.tableName = this.$refs[0][0]) : "";
          // 默认选中列表
          this.selected_temp = JSON.parse(JSON.stringify(this.act_form.list));
        }
        // isSelect 是否进行状态勾选
        isSelect ? this.toggleSelection() : "";
      });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      this.getGoods(this.selected ? true : "");
    },
    // 刷新
    refresh() {
      this.searchArr.forEach(item => {
        item.value = "";
      });
      this.search();
    },

    /****     多选功能模块 start       ****/
    // 判断为空值,t=1则空值类型包含0与'0'
    isEmpty(v, t = 0) {
      switch (typeof v) {
        case "undefined":
          return true;
        case "string":
          if (v.length == 0 || t ? v === "0" : false) return true;
          break;
        case "boolean":
          if (!v) return true;
          break;
        case "number":
          if (isNaN(v) || t ? v === 0 : false) return true;
          break;
        case "object":
          if (null === v) {
            return true;
          } else if (!Object.keys(v).length) {
            return true;
          }
          break;
        default:
          return false;
      }
    },
    //确定多选商品
    chooseGoods() {
      this.act_form.list = JSON.parse(JSON.stringify(this.selected_temp));
      this.showGoods = false;
      this.$emit("getSelectedList", this.act_form.list, this.index);
    },
    // 切换勾选状态
    toggleSelection() {
      // this.$refs.multipleTable获取不到
      this.$nextTick(() => {
        let goodsIdList = this.act_form.list.map(item => item[this.key]);
        let selected_temp = this.selected_temp.map(item => item[this.key]);
        // 本页数据的勾选
        this.tableData.forEach(item => {
          // 不在已选商品中则去除勾选
          if (goodsIdList.indexOf(item[this.key]) === -1) {
            if (this.hasTab) {
              this.tableName.toggleRowSelection(item, false);
            } else {
              this.$refs.multipleTable.toggleRowSelection(item, false);
            }
          }
          // 在全局勾选列表中添加勾选
          if (selected_temp.indexOf(item[this.key]) !== -1) {
            if (this.hasTab) {
              this.tableName.toggleRowSelection(item, true);
            } else {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          }
        });
      });
    },
    // 手动全选
    handleSelectAll(selection) {
      let selections = selection.map(item => item[this.key]); // 用户手动选中的所有项id列表集合
      let selected_list = this.selected_temp.map(item => item[this.key]); // 所有选中项id列表集合
      // 当前页里有被勾选的 只执行勾选操作
      let flag = this.tableData.some(
        item => selections.indexOf(item[this.key]) !== -1
      );
      for (var i = 0; i < this.tableData.length; i++) {
        let item = this.tableData[i];
        if (selected_list.indexOf(item[this.key]) !== -1 && !flag) {
          // 本页数据在全局勾选列表中 并且 本页数据不在用户手选列表中（表明执行了取消勾选操作）
          // 将本页数据从全局勾选列表中删除
          let index = this.selected_temp.findIndex(
            value => value[this.key] === item[this.key]
          );
          this.selected_temp.splice(index, 1);
        } else {
          // 本页数据项不在全局勾选列表中（执行全选操作） - 将数据项添加进全局勾选列表
          if (selected_list.indexOf(item[this.key]) === -1) {
            this.selected_temp.push(item);
          }
        }
      }
    },
    // 手动单选
    handleSelect(selection, row) {
      // 判断总勾选列表中是否含有点击项
      let includeFlag = this.selected_temp.some((item, index) => {
        return item[this.key] == row[this.key];
      });
      if (includeFlag) {
        // 有-删除
        let index = this.selected_temp.findIndex(
          item => item[this.key] === row[this.key]
        );
        this.selected_temp.splice(index, 1);
      } else {
        // 无-添加
        this.selected_temp.push(row);
      }
    },
    /****     多选功能模块 end       ****/
    goodsCodesReplace(e){
      return e.replace(/\s/g,'').replace(/[^0-9]/g,'')
    }
  },
  beforeDestroy() {
    // console.log('be')
  }
};
</script>